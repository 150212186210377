import request from 'graphql-request'
import { atomsWithQuery } from 'jotai-tanstack-query'
import { DEX_VOLUME_QUERY } from '~/queries/volume'
import { getEnvConfigValue } from '~/utils/envConfig'

const fetchDexVolume = async () => {
	try {
		const volumeRequest = await request(
			getEnvConfigValue('EXCHANGE_SUBGRAPH_ENDPOINT') as string,
			DEX_VOLUME_QUERY
		)
		if (volumeRequest.pancakeFactories.length === 0) return null
		return {
			totalVolumeUSD: volumeRequest.pancakeFactories[0]
				.totalVolumeUSD as string,
			totalVolumeBNB: volumeRequest.pancakeFactories[0].totalVolumeBNB as string
		}
	} catch (err) {
		return null
	}
}

export const [dexVolumeAtom] = atomsWithQuery(() => ({
	initialData: null,
	queryKey: ['dexVolumeAtom'],
	queryFn: fetchDexVolume
}))
