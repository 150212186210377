import Image from 'next/image'
import Link from 'next/link'
import ConnectWallet from '~/components/ConnectWallet'
import LandingBackground from '~/media/landingBackground.svg'
import Certik from '~/media/certik.svg'
import SwapImg from '~/media/swap.png'
import ExchangeImg from '~/media/exchange.png'
import LiquidityImg from '~/media/liquidity.png'
import Glow from '~/media/glow.png'
import SocialLinks from '~/components/SocialLinks'
import clsx from 'clsx'
import React, { useEffect } from 'react'

const Hero = () => {
	return (
		<div>
			<h1 className="w-full justify-center px-[10%] py-4 text-center text-3xl uppercase leading-[1] sm:text-4xl md:text-5xl lg:text-7xl">
				YOUR <span className="text-accent">REFI</span> DEX
			</h1>
			<div className="absolute bottom-[12%] left-0 right-0 z-10 flex w-full flex-row justify-between gap-2 sm:bottom-[8%] lg:flex-col">
				<div className="w-full text-center">
					<h2 className="mb-6 text-center text-xl uppercase md:text-2xl lg:text-4xl">
						SWAP. EARN. GROW.
					</h2>
					<Link
						href="/swap"
						className="btn rounded-full border-0 bg-accent px-10 text-base font-bold uppercase md:text-xl"
					>
						Trade now
					</Link>
				</div>
			</div>
		</div>
	)
}

export default Hero
