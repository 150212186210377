import { gql } from 'graphql-request'

export const DEX_VOLUME_QUERY = gql`
	{
		pancakeFactories(first: 1) {
			totalVolumeUSD
			totalVolumeBNB
			totalPairs
			totalTransactions
			totalLiquidityUSD
			totalVolumeBNB
		}
	}
`
