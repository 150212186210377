import clsx from 'clsx'

const Stat = ({
	title,
	value,
	valueAlignment = 'center',
	className
}: {
	title: string
	valueAlignment?: 'left' | 'center' | 'right'
	value: undefined | string | number
	className?: string
}) => {
	const valueAlignmentClasses = {
		left: 'items-start',
		center: 'items-center',
		right: 'items-end'
	}

	return (
		<div
			className={clsx(
				'my-4 flex w-full flex-col justify-center px-4 md:w-fit md:border-l-2',
				valueAlignmentClasses[valueAlignment],
				className
			)}
		>
			<p className="mb-0 text-2xl uppercase md:text-4xl">{value || 0}</p>
			<p className="mt-2 text-sm font-light uppercase md:text-base">{title}</p>
		</div>
	)
}

export default Stat
