import axios from 'axios'
import { formatUnits, isAddress } from 'ethers/lib/utils.js'
import { atomsWithQuery } from 'jotai-tanstack-query'
import { IUserFarmsRes } from '~/pages/api/users/[address]'
import { Masterchef, UserFarm } from '~/types/farms'
import { addressAtom } from './user'

export const fetchMasterchef = async () => {
	const { data } = await axios.get<Masterchef>('/api/farms')
	return data
}

const emptyMasterchef: Masterchef = {
	kswapRateToBurn: '',
	id: '',
	pools: [],
	totalRegularAllocPoint: '',
	totalSpecialAllocPoint: '',
	kswapRateToRegularFarm: '',
	kswapRateToSpecialFarm: ''
}

export const [baseMasterchef] = atomsWithQuery(() => {
	return {
		queryKey: ['masterchef'],
		queryFn: fetchMasterchef,
		initialData: emptyMasterchef,
		refetchInterval: 10_000
	}
})

async function getMasterChefStats() {
	const data = await fetchMasterchef()

	const farmsCount = data.pools.length
	const stakedValue = data.pools.reduce(
		(acc, curr) => acc + Number(formatUnits(curr.slpBalance)),
		0
	)

	return {
		farmsCount,
		stakedValue
	}
}

export const [masterChefStats] = atomsWithQuery(() => {
	return {
		queryKey: ['masterchef'],
		queryFn: getMasterChefStats,
		initialData: {},
		refetchInterval: 10_000
	}
})

export const fetchUserFarms = async (address: string) => {
	const resMap = new Map<string, UserFarm>()
	if (!isAddress(address)) return resMap
	try {
		const { data } = await axios.get<IUserFarmsRes>(
			`/api/users/${address.toLowerCase()}`
		)
		for (const [key, value] of Object.entries(data)) {
			resMap.set(key, value)
		}
	} catch {}
	return resMap
}

export const [userFarmsAtom] = atomsWithQuery((get) => {
	return {
		queryKey: ['userFarms', get(addressAtom)],
		queryFn: ({ queryKey: [, address] }) => fetchUserFarms(address as string),
		initialData: new Map<string, UserFarm>(),
		enabled: get(addressAtom) !== '',
		refetchInterval: 10_000
	}
})

const fetchLastBlock = async () => {
	const { data } = await axios.get<Masterchef>('/api/lastBlock')
	return Number(data)
}

export const [lastBlockAtom] = atomsWithQuery(() => {
	return {
		queryKey: ['lastBlock'],
		queryFn: fetchLastBlock,
		initialData: 0,
		refetchInterval: 10_000
	}
})
