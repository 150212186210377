import Link from 'next/link'
import logo from 'media/logo-no-text.svg'
import Image from 'next/image'

const Pledge = () => {
	return (
		<section className="relative my-2 flex min-h-screen w-[100vw] flex-col items-center justify-center gap-8 px-[5%] py-8 lg:px-[10%] 2xl:px-[20%]">
			<Image
				src={logo}
				alt="KyotoSwap Logo"
				className="max-h-[25%] max-w-[25%]"
				width={200}
				height={200}
			/>
			<p className="mt-8 text-center text-3xl font-bold uppercase text-primary sm:text-4xl md:text-5xl ">
				THE KYOTOSWAP <br />{' '}
				<span className="border-b-4 border-solid border-accent">PLEDGE</span>
			</p>
			<p className="mt-8 max-w-[540px] text-center text-base font-normal uppercase text-primary lg:text-xl">
				Every tree planted reduces the circulating supply of KSWAP tokens
			</p>
			<div className="mt-0 flex w-full flex-row justify-center gap-8">
				<Link
					href="/farms"
					className="btn mt-8 rounded-full border-0 bg-accent px-10 text-base md:text-xl"
				>
					FARM KSWAP
				</Link>
				<Link
					href="/impact"
					className="btn mt-8 rounded-full border-0 bg-accent px-10 text-base md:text-xl"
				>
					PLANT KSWAP
				</Link>
			</div>
		</section>
	)
}

export default Pledge
