import Head from 'next/head'
import GamifiedPlanting from '~/components/Landing/GamifiedPlanting'
import Hero from '~/components/Landing/Hero'
import KyotoSwapToken from '~/components/Landing/KyotoSwapToken'
import Pledge from '~/components/Landing/Pledge'

export default function Home() {
	return (
		<>
			<Head>
				<title>Home | KyotoSwap</title>
			</Head>
			<section className="flex h-screen w-[100vw] flex-col justify-evenly  bg-homepage bg-cover !bg-local bg-center md:relative md:items-center">
				<Hero />
			</section>
			<section className="z-[1] min-h-screen w-[100vw] bg-gamified bg-cover bg-no-repeat px-8 pb-40 md:py-20 lg:bg-max">
				<GamifiedPlanting />
			</section>
			<section className="-mt-[30%] min-h-[200vh] w-[100vw] bg-pledge bg-cover bg-center bg-no-repeat md:-mt-[200px]">
				<Pledge />
				<KyotoSwapToken />
			</section>
		</>
	)
}
