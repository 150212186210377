import Image from 'next/image'
import Link from 'next/link'
import Stats from '~/components/Landing/Stats'

const GamifiedPlanting = () => {
	return (
		<div className="relative flex min-h-full flex-col items-center justify-center gap-8 text-white sm:flex-row md:gap-8">
			<div className="flex w-full flex-col items-center justify-center text-center sm:w-1/2">
				<h3 className="text-3xl sm:text-4xl md:text-5xl">
					GAMIFIED <br /> TREE PLANTING
				</h3>
				<h4 className="mb-6 mt-6 text-xl md:text-2xl">POWERED BY KYOTOSWAP</h4>
				<Stats />
				<Link
					href="/impact"
					className="btn mt-10 rounded-full border-0 bg-accent px-10 text-base md:text-xl"
				>
					LEADERBOARD
				</Link>
			</div>
			<div className="relative flex items-end justify-center md:w-1/2">
				<Image
					src="/imgs/gamified-leaderboard.png"
					width="500"
					height="600"
					alt="Gamified Planting Leaderboard"
				/>
			</div>
		</div>
	)
}

export default GamifiedPlanting
