import { useAtom } from 'jotai'
import Stat from '~/components/Stat/Stat'
import { dexVolumeAtom } from '~/stores/dex'
import { landingStatsAtom } from '~/stores/stats'
import { toFormattedValueDN } from '~/utils'

const Stats = () => {
	const [stats] = useAtom(landingStatsAtom)
	const [dexVolume] = useAtom(dexVolumeAtom)

	return (
		<div className="relative mt-4">
			<div className="grid w-full grid-cols-1 items-center justify-items-center sm:justify-evenly xl:grid-cols-3 ">
				<Stat
					title="TOTAL DEX VOLUME"
					valueAlignment="left"
					value={`$${
						dexVolume?.totalVolumeUSD
							? toFormattedValueDN(Number(dexVolume.totalVolumeUSD), 0)
							: null
					}`}
				/>
				<Stat
					title="Trees Planted"
					valueAlignment="left"
					value={
						stats?.treesPlanted
							? toFormattedValueDN(stats.treesPlanted, 0)
							: '0'
					}
				/>
				<Stat
					title="Planting wallets"
					valueAlignment="left"
					value={
						stats?.plantingWallets
							? toFormattedValueDN(stats.plantingWallets, 0)
							: '0'
					}
				/>
			</div>
		</div>
	)
}

export default Stats
