import Stat from '~/components/Stat/Stat'
import useKswapStats from '~/hooks/useTokenStats'
import { kswapToken } from '~/stores/tokens'
import { getEnvConfigValue } from '~/utils/envConfig'

const Figures = () => {
	const { tokenStats } = useKswapStats(kswapToken().address, [
		'0xe56A248C316172D71238a30EbE936cD50bC91dcc',
		getEnvConfigValue('MASTERCHEF_ADDRESS'),
		'0x000000000000000000000000000000000000dEaD'
	])

	return (
		<section className="w-[100vw] flex-col items-start justify-center gap-8 bg-transparent px-[5%] pb-16 pt-40 md:max-w-[700px] lg:max-w-[1000px] lg:px-[10%] xl:max-w-[1500px]">
			<div className="px-10">
				<div className="grid grid-cols-1 gap-4 text-primary md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
					<Stat
						className="border-primary"
						valueAlignment="left"
						title="Circulating KSWAP"
						value={tokenStats?.circulatingSupply}
					/>
					<Stat
						className="border-primary"
						valueAlignment="left"
						title="STAKED KSWAP"
						value={tokenStats?.stakedValue}
					/>
					<Stat
						className="border-primary"
						valueAlignment="left"
						title="KSWAP BURNED"
						value={tokenStats?.burned}
					/>
					<Stat
						className="border-primary"
						valueAlignment="left"
						title="KSWAP HOLDERS"
						value={tokenStats?.holdersCount}
					/>
					<Stat
						className="border-primary"
						valueAlignment="left"
						title="KSWAP Emissions"
						value={tokenStats?.emissions}
					/>
					<Stat
						className="border-primary"
						valueAlignment="left"
						title="KSWAP Farms"
						value={tokenStats?.farmsCount}
					/>
				</div>
			</div>
		</section>
	)
}

export default Figures
