import Link from 'next/link'
import Image from 'next/image'
import Figures from '~/components/Landing/Figures'
import logo from '~/media/swap-token.svg'

const KyotoSwapToken = () => {
	return (
		<section className="relative min-h-screen w-[100vw] flex-col items-start justify-center px-[5%] py-8 md:gap-4 lg:px-[10%]">
			<div className="z-10 col-span-3 flex h-full flex-col justify-around gap-8 md:col-span-2">
				<div className="flex flex-col items-center justify-center gap-2 text-center">
					<Image
						src={logo}
						alt="KyotoSwap Logo"
						className="max-h-[10%] max-w-[10%]"
						width={60}
						height={100}
					/>
					<p className="mt-8 text-center text-3xl font-bold uppercase text-primary sm:text-4xl md:text-5xl ">
						THE KYOTOSWAP{' '}
						<span className="border-b-4 border-solid border-accent">TOKEN</span>
					</p>
					<p className="mt-8 max-w-[540px] text-center text-base font-normal uppercase text-primary lg:text-xl">
						KyotoSwap’s governance and reward token
					</p>
					<Figures />
					<div className="mt-12 flex w-full flex-col justify-center gap-6 sm:flex-row">
						<Link href="/farms" passHref>
							<button className="btn w-fit rounded-full border-0 bg-accent px-10 text-base font-bold uppercase text-primary md:text-xl ">
								FARM KSWAP
							</button>
						</Link>
						<Link
							href="https://kyotofoundation.gitbook.io/kyotoswap/welcome/governance-and-tokenomics"
							passHref
							target="_blank"
						>
							<button className="btn w-fit rounded-full border-0 bg-accent px-10 text-base font-bold uppercase text-primary md:text-xl">
								TOKENOMICS
							</button>
						</Link>
					</div>
				</div>
			</div>
		</section>
	)
}

export default KyotoSwapToken
