import { useQuery } from '@tanstack/react-query'
import { readContract } from '@wagmi/core'
import { BigNumber, constants } from 'ethers'
import { formatUnits } from 'ethers/lib/utils.js'
import { useAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { useToken } from 'wagmi'
import { TOKEN_HELPER_ABI } from '~/abis/tokenHelper.abi'
import { masterChefStats } from '~/stores/farms'
import { landingStatsAtom } from '~/stores/stats'
import { kswapAtom } from '~/stores/tokens'
import { TokenStats } from '~/types/tokens'
import { toFormattedValueDN } from '~/utils'
import { getEnvConfigValue } from '~/utils/envConfig'

export default function useKswapStats(
	address: string,
	_burnAddresses?: string[]
) {
	const burnAddresses: string[] = [
		constants.AddressZero,
		'0x000000000000000000000000000000000000dEaD'
	]

	const [masterStats] = useAtom(masterChefStats)
	const [stats] = useAtom(landingStatsAtom)

	useEffect(() => {
		if (_burnAddresses) burnAddresses.push(..._burnAddresses)
	}, [])

	const [kswap] = useAtom(kswapAtom)

	const [tokenStats, setTokenStats] = useState<TokenStats | null>(null)
	const { data, isLoading } = useToken({
		address: address as `0xString`
	})

	const { data: burnedData, isLoading: isLoadingBurned } = useQuery({
		queryKey: ['burnedBalances'],
		queryFn: async () => {
			const tokenAddresses = _burnAddresses as `0x${string}`[]
			const data = await readContract({
				address: getEnvConfigValue('TOKEN_HELPER_ADDRESS'),
				abi: TOKEN_HELPER_ABI,
				functionName: 'balances',
				args: [tokenAddresses, [address as `0x${string}`]]
			})
			const totalBurned = data.reduce<BigNumber>((acc, curr) => {
				return acc.add(curr)
			}, BigNumber.from(0))
			return totalBurned
		},
		enabled: !!_burnAddresses
	})

	useEffect(() => {
		if (!data || !burnedData || !kswap || !masterStats || !stats) return
		const tokenStats: TokenStats = {
			circulatingSupply: toFormattedValueDN(
				Number(
					burnedData
						? formatUnits(data.totalSupply.value.sub(burnedData), data.decimals)
						: data.totalSupply.formatted
				),
				0
			),
			totalSupply: toFormattedValueDN(Number(data.totalSupply.formatted), 0),
			marketCap: toFormattedValueDN(
				Number(
					burnedData
						? formatUnits(data.totalSupply.value.sub(burnedData), data.decimals)
						: data.totalSupply.formatted
				) * Number(kswap.derivedUSD),
				0
			),
			burned: burnedData
				? toFormattedValueDN(Number(formatUnits(burnedData, data.decimals)), 0)
				: '0.00',
			emissions: stats.emission
				? toFormattedValueDN(
						Number(formatUnits(stats.emission, data.decimals)),
						0
				  )
				: '0.00',
			stakedValue: masterStats.stakedValue
				? toFormattedValueDN(masterStats.stakedValue, 0)
				: '0.00',
			farmsCount: masterStats.farmsCount
				? toFormattedValueDN(masterStats.farmsCount, 0)
				: '0',
			holdersCount: stats.holdersCount
				? toFormattedValueDN(stats.holdersCount, 0)
				: '0'
		}

		setTokenStats(tokenStats)
	}, [data, burnedData, kswap, masterStats, stats])
	return { tokenStats, isLoading: isLoading || isLoadingBurned }
}
